<template>
  <div class="body">
    <div class="head-class">
      <div class="head-item" v-for="(i, index) in itemList" :key="index">
        <div class="item-title" :style="{ backgroundColor: i.backgroundColor }">
          {{ i.title }}
        </div>
        <div class="item-number" :style="{ color: i.backgroundColor }">
          {{ i.total}}
        </div>
        <div class="item-bottom">
          <div>逾期：{{ i.overdue }}</div>
          <div>总：{{ i.notOverdue }}</div>
        </div>
      </div>
    </div>
    <div class="home-class">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <!-- <el-form-item label="电站">
          <el-input v-model="formInline.number" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="编号">
          <el-input
            v-model="formInline.workOrderNo"
            placeholder="编号"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="创建时间">
          <el-date-picker
            placeholder="请选择日期"
            v-model="formInline.date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="缺陷级别">
          <el-select v-model="formInline.value" placeholder="请选择">
            <el-option
              v-for="item in formInline.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷类型">
          <el-select v-model="formInline.value" placeholder="请选择">
            <el-option
              v-for="item in formInline.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="消缺结果">
          <el-select v-model="formInline.value" placeholder="请选择">
            <el-option
              v-for="item in formInline.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="hand-class">
        <img
          src="../../assets/images/yunwei/add.png"
          @click="addClick(1)"
          alt=""
        />
        <img
          src="../../assets/images/yunwei/edit.png"
          @click="addClick(2)"
          alt=""
        />
        <!-- <img src="../../assets/images/yunwei/delete.png" alt=""> -->
        <img src="../../assets/images/yunwei/export.png" alt="" />
      </div>
      <div style="clear:both">
        <el-table
          :data="tableData"
          highlight-current-row
          style="width: 100%;background:none;"
          height="42vh" max-height="44vh"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column label="厂站名称" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.jsonData.powerStationName }}</span>
                    </template>
</el-table-column> -->

          <el-table-column label="工单编号" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.workOrderNo }}</span>
            </template>
          </el-table-column>

          <el-table-column label="工单类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.workOrderName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="工单内容" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.workOrderContent }}</span>
            </template>
          </el-table-column>

          <el-table-column label="管理状态" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.row.workOrderOverStatus === 0
                  ? "未开始"
                  : scope.row.workOrderOverStatus === 1
                  ? "处理中"
                  : scope.row.workOrderOverStatus === 2
                  ? "已完成"
                  :scope.row.workOrderOverStatus === 3
                  ? "已终止"
                  : ""
              }}</span>
            </template>
          </el-table-column>

          <el-table-column label="当前处理人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="其他操作人员" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.number }}</span>
            </template>
          </el-table-column>
          <el-table-column label="计划开始时间" width="95px" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.planStartTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="计划完成时间" width="95px" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.planEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否逾期" align="center">
            <template slot-scope="scope">
              <span style="color:green" v-if="scope.row.isOverdue == 0">未逾期</span>
              <span style="color:red" v-if="scope.row.isOverdue == 1">逾期</span>
            </template>
          </el-table-column>
          <el-table-column label="处理结果" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.row.dealResult === 1 ? "问题未解决" : "问题已解决"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100px"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="detailClick(scope.row)"
                >详情</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.workOrderOverStatus == 0 || scope.row.workOrderOverStatus == 1"
                :disabled="scope.row.workOrderOverStatus == 2 && scope.row.workOrderOverStatus == 3"
                @click="changeClick(scope.row)"
                >更换处理人</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.workOrderOverStatus == 0 || scope.row.workOrderOverStatus == 1"
                :disabled="scope.row.workOrderOverStatus == 2 && scope.row.workOrderOverStatus == 3"
                @click="stopClick(scope.row)"
                >终止</el-button
              >
              <el-button
                type="text"
                :disabled="scope.row.workOrderOverStatus != 2"
                @click="deleteClick(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[2, 5, 10, 15]"
          :page-size="size"
          class="pagination-style"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div v-if="detailVisible === true">
      <work-order-dialog
        :detailId="detailId"
        @closeDialog="closeDialog"
      ></work-order-dialog>
    </div>
    <el-dialog
        title="更换处理人"
        :visible.sync="userNameVisible"
        class="userDialog"
        width="500px"
        @close="closeDialog"
        v-loading="loading"
        >
        <el-select style="width: 300px;" v-model="workOrderNameData" multiple placeholder="请选择处理人" @change="setworkOrder">
            <el-option
              v-for="item in workOrderList"
              :key="item.workOrderNo"
              :label="item.workOrderName"
              :value="item.workOrderNo"
            >
            </el-option>
          </el-select>
          <!-- :value="{
                workOrderNo: item.workOrderNo,
                userName: item.workOrderName,
              }" -->
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmChange">确 定</el-button>
            <el-button @click="closeDialog">取 消</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import WorkOrderDialog from "./dialog/workOrderDialog.vue";
export default {
  components: {
    WorkOrderDialog,
  },
  mounted() {
    this.getTableList();
    this.getStatus();
  },
  data() {
    return {
      formInline: {},
      itemList: [],
      tableData: [],
      total: 0,
      size: 10,
      currentPage: 1,
      detailVisible: false, // 是否显示详情弹框
      isBan: true,
      selectList: [],
      detailId: null,
      workOrderList: [],
      workOrderListData: {},
      userNameVisible: false,//变更处理人
      changeRow: {},
      workOrderNameData: '',//工单流
      loading: false,

    };
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    // 查询
    onSubmit() {
      this.getTableList();
    },
    detailClick(row) {
      console.log(row,246);
      //关联告警
      this.$store.commit('workOrder/setTableList',row.jsonData.tableData )
      // console.log(  this.tableDatas,247);
      this.detailId = row.workOrderApplyCode;
      this.detailVisible = true;
    },
    changeClick(row){
      this.changeRow = row
      this.getworkOrderNo()
    },
    // 获取工单流
    async getworkOrderNo() {
      let data = {
        currPage: 1,
        pageSize: 100000,
      };
      // 待变更人列表
      const res = await this.$http.post("WorkOrder/workList", data);
      if (res.data.code === 200) {
        this.workOrderList = res.data.data.data.map(item=>{
          this.workOrderListData[item.workOrderNo] = item.workOrderName
          return item
        })
        this.userNameVisible = true
      }
    },
    setworkOrder(val){
      console.log('a,',val)
    },
    confirmChange(val){
      const that = this
      const ids = this.changeRow.userIds.split(',')
      let _ids
      let data = this.workOrderNameData.map((item,index)=>{
        return {
          workOrderNo: item,
          userName: that.workOrderListData[item],
          id: ids[index]
        }
      })
      if(ids.length>this.workOrderNameData.length){
        _ids = ids.slice(this.workOrderNameData.length).map(item=>{return {id: item}})
        data = data.concat(_ids)
      }
      // console.log('confirmChan--ge',data,_ids)
      // return
      this.loading = true
      this.$http.post("flowWorkOrderApplyUser/updateApplyUser", data).then((res) => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: "提示",
            message: "变更处理人成功",
            showClose: true,
          });
          this.loading = false
          this.userNameVisible = false
          this.getTableList();
        }
      });
    },
    stopClick(row){
      this.$confirm(`确认终止工单${row.workOrderNo}?`).then(() => {
        const data = {
          id: row.id,
          workOrderOverStatus: 3
        }
        this.$http.post("WorkOrder/updateWork", data).then((res) => {
          if (res.data.code === 200) {
            this.$notify.success({
              title: "提示",
              message: "工单已终止",
              showClose: true,
            });
          }
        });
      }).catch(()=>{})
    },
    deleteClick(id) {
      this.$http.post("WorkOrder/batchDelete", [id]).then((res) => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true,
          });
        }
      });
    },
    //新建工单
    addClick(index) {
      if (this.selectList.length != 1 && index === 2) return;
      var id = undefined;
      if (index === 2) {
        id = this.detailId;
      }
      this.$router.push({ path: "/yunwei/yunwei/addWorkOrder", query: { id } });
    },
    closeDialog() {
      this.detailVisible = false;
      this.userNameVisible = false
    },
    //表格勾选
    handleSelectionChange(val) {
      this.selectList = [];
      val.forEach((item) => {
        this.selectList.push(item.workOrderApplyCode);
      });
      if (val.length != 1) {
        this.isBan = true;
      } else {
        this.isBan = false;
        this.detailId = val[0].workOrderApplyCode;
      }
    },

    // 表格数据
    getTableList() {
      let params = {
        condition: this.formInline,
        currPage: this.currentPage,
        pageSize: this.size,
      };
      params.condition.powerStationCode =
        localStorage.getItem("powerStationCode");
      // params.condition.powerStationName =
      //   localStorage.getItem("powerStationName");
      this.$http.post("flowWorkOrderApply/list", params).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          console.log("tableData", this.tableData);
          this.tableData.map((item, index) => {
            this.tableData[index].jsonData = JSON.parse(item.jsonData);
          });

          this.total = res.data.data.count;
          console.log("tableData", this.tableData);
        }
      });
    },

    // 顶部数据
    getStatus() {
      // 待分配
      this.$http
        .post("WorkOrder/waitDistributionCount", {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          powerStationCode: localStorage.getItem("powerStationCode"),
          powerStationName: localStorage.getItem("powerStationName"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.itemList.push({
              backgroundColor: "#ec808d",
              title: "待分配",
              ...res.data.data,
            });
          }
        });
      // 处理中
      this.$http
        .post("WorkOrder/inHandCount", {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          powerStationCode: localStorage.getItem("powerStationCode"),
          powerStationName: localStorage.getItem("powerStationName"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.itemList.push({
              backgroundColor: "#facd91",
              title: "处理中",
              ...res.data.data,
            });
          }
        });
      // 已完成
      this.$http
        .post("WorkOrder/completedCount", {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          powerStationCode: localStorage.getItem("powerStationCode"),
          powerStationName: localStorage.getItem("powerStationName"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.itemList.push({
              backgroundColor: "#81d3f8",
              title: "已完成",
              ...res.data.data,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  background: #223f6c;
  // width: 100%;
  height: 100%;
  padding: 10px;
  .head-class {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .head-item {
      width: 33%;
      // height: 260px;
      border-radius: 5px;
      border: 1px solid #2c4e73;
      background-color: #21527e;
      box-sizing: border-box;
      .item-title {
        height: 55px;
        box-sizing: border-box;
        font-family: "Arial Bold", "Arial", sans-serif;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
        line-height: 55px;
        font-size: 18px;
      }

      .item-number {
        box-sizing: border-box;
        font-family: "Arial Bold", "Arial", sans-serif;
        font-weight: 700;
        font-size: 30px;
        margin: 50px 0;
        text-align: center;
      }

      .item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 19px;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #ffffff;
        font-size: 17px;
      }
    }
  }

  .hand-class {
    display: flex;
    text-align: right;
    float: right;
    margin: 0 0 10px;

    img {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin: 0 5px;
    }
  }

  .home-class {
    height: 62vh;
    background-color: #2d5981;
    box-sizing: border-box;
    padding: 25px 25px 20px;
  }
}

/deep/ .el-form-item__label {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
  text-align: left;
  // line-height: normal;
  font-size: 16px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/.el-table__body tr.current-row>td{
  background-color: #223f6c;
}
/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

/deep/ .el-table__body tr.hover-row > td {
  background-color: transparent !important;
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;

  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}
.userDialog {
  /deep/.el-input__inner {
    color: #606266;
  }
}
</style>
