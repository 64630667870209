<template>
    <div>
        <el-dialog
        title="工单详情"
        :visible.sync="addDialogVisible"
        width="80%"
        @close="closeDialog"
        >
            <div class="head-class">
                <div
                v-for="(item,index) in titleList"
                :class="item.value === currentNavigation?'item-head-select':'item-head-unSelect'"
                :key="index"
                @click="currentNavigation = item.value"
                >
                    <div class="title-class">{{item.name}}</div>
                    <img v-if="item.value === currentNavigation" src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
                </div>
            </div>
                <WorkOrderDetail :detailId="detailId" v-if="currentNavigation===0"/>
                <DealFlow :detailId="detailId" v-if="currentNavigation===1"/>
                <FlowChart v-if="currentNavigation ===2"/>
                <CorrelationAlart :detailId="detailId" v-if="currentNavigation ===3"/>
        </el-dialog>
    </div>
</template>
<script>
import DealFlow from '../components/dealFlow.vue'
import FlowChart from '../components/flowChart.vue'
import WorkOrderDetail from '../components/workOrderDetail.vue'
import CorrelationAlart from '../components/correlationAlart.vue'
export default {
    props: {
        detailId: {
            typeof: String,
            default: ''
        },
    },
    components: {
        WorkOrderDetail,
        DealFlow,
        FlowChart,
        CorrelationAlart
    },
  data() {
    return {
        addDialogVisible: true,
        titleList: [{name: '工单详情',value: 0},
        {name: '处理流水',value: 1},
        {name: '流程图',value: 2},
        {name: '关联告警',value: 3}],
        currentNavigation: 0, // 顶部导航
    }
  },
  methods:{
    closeDialog(){
        this.addDialogVisible = false
        this.$emit('closeDialog')
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
    height: 75%;
}
/deep/ .el-dialog__body{
    padding: 3px 20px;
}
.head-class{
    display: flex;
    border-bottom: 1px solid #009fde;
    .item-head-select{
        margin-right: 10px;
        display: flex;
        height: 38px;
        width: 100%;
        position: relative;
        .title-class{
            width: 95px;
            height: 100%;
            line-height: 40px;
            padding-left: 5px;
            font-family: "Arial", sans-serif;
            color: #ffffff;
            font-size: 15px;
            background: #00b2e6;
        }
        .background-img{
            height: 100%;
            position: absolute;
            left: 61px;
        }
    }
    .item-head-unSelect{
        display: flex;
        align-items: center;
        height: 38px;
        width: 100%;
        font-family: "Arial", sans-serif;
        color: #aaaaaa;
        font-size: 15px;
    }
}
.form-class{
    /deep/  .el-form-item__label{
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 15px;
        width: 150px;
    }
    /deep/ .el-input__inner{
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 13px;
    }
}
</style>
