<template>
  <div class="correlation-alart">
    <el-table
      :data="tableData"
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="厂站名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.powerStationName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="告警名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.signalName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="级别" align="center">
        <template slot-scope="scope">
          <span style="color:green" v-if="scope.row.level==0">正常状态</span>
          <span style="color:red" v-if="scope.row.level==1">紧急</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
        <span style="color:green" v-if="scope.row.status==1">激活</span>
          <span style="color:red" v-if="scope.row.status==0">消缺</span>
        </template>
      </el-table-column>
      <el-table-column label="产生时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.recordTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修复建议" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.dealMessage}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[2, 5, 10, 15]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
  </div>
</template>
<script>
export default {
  props: {
    detailId: {
      typeof: String,
      default: null,
    },
  },
  mounted() {
    // this.getData();
  },
  data() {
    return {
      tableData: [],
      total: 0,
      size: 10,
      currentPage: 1,
    };
  },
  created(){
    this.tableData = this.$store.state.workOrder.tableList
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      // this.getListData()
    },
    handleCurrentChange() {
      this.currentPage = val;
      // this.getListData()
    },
    // getData(){
    //     this.$http.post('WorkOrder/correlationAlarm',{WorkOrderApplyCode: this.detailId}).then(res=>{
    //         if(res.data.code===200){
    //             this.tableData = res.data.data
    //         }
    //     })
    // }
  },
};
</script>
<style scoped lang="scss">
.correlation-alart {
  margin-top: 20px;
  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
  }
  /deep/ .el-table--border th {
    background-color: #d7d7d7;

    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #000000;
  }
  /deep/ .el-table td {
    background-color: #ffffff;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #555555;
  }
  /deep/ .el-table__body-wrapper {
    background: #ffffff;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff !important;
  }
  /deep/ .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  /deep/ .el-pagination button:disabled {
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #333;
    background-color: #fff;
    cursor: default;
  }
  /deep/ .el-pager li {
    background: none;
    color: #333;
  }
  /deep/ .el-icon {
    color: #333;
  }
  /deep/ .el-pagination .btn-next {
    background: none;
  }
  /deep/ .el-pagination__total {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
  /deep/ .el-pagination__jump {
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
  /deep/ .el-input__inner {
    color: #333;
  }
}
</style>
