<template>
    <div class="deal-flow-class">
        <div class="item-title">
            <div class="title-left"></div>
            <div>流转意见</div>
        </div>
        <div v-for="(item,index) in flowList" :key="index" class="item-class">
            <img src="../../../assets/images/user-icon.png" style="width:49px;height:49px" alt="">
            <div style="margin: 0 20px;width:90px">{{item.commitUser}}</div>
            <div class="content-class">
                <div class="line-class">
                    <div>流转意见：{{item.proposal}}</div>
                    <div>{{item.nodeCreateTime}}</div>
                </div>
                <div class="line-class">
                    <div>接收人：{{item.receiveUser}}</div>
                    <div>{{item.acceptance===0? '不合格': item.acceptance===1?'验收已合格':'未验收'}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        detailId: {
            typeof: String,
            default: null
        }
    },
    mounted(){
        this.getData()
    },
    data(){
        return {
            flowList: []
        }
    },
    methods: {
        getData(){
            this.$http.post('WorkOrder/workOrderProcessedChain',{workOrderApplyCode:this.detailId}).then(res=>{
                if(res.data.code===200){
                    this.flowList = res.data.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.deal-flow-class{
    .item-title{
        display: flex;
        align-items: center;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 14px;
        margin: 15px 5px;
        .title-left{
            height: 20px;
            width: 5px;
            border-radius: 5px;
            border: 1px solid #02a7f0;
            background-color: #02a7f0;
            margin-right: 5px;
        }
    }
    .item-class{
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #7f7f7f;
        font-size: 14px;
        margin: 10px;
        border-bottom: 1px solid #d7d7d7;
        margin-left: 20px;
        .content-class{
            width: 100%;
            .line-class{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 35px;
                border-bottom: 1px solid #d7d7d7;
                margin-bottom: 12px;
            }
        }
    }
}
</style>