<template>
    <div class="body">
        <div class="content-class">
            <el-form label-width="120px" :model="itemForm" class="form-class" ref="form">
                <el-form-item label="工单类型：" required>
                    <div class="work-class">
                        <div @click="itemForm.workOrderType = item.value"
                            :class="itemForm.workOrderType == item.value ? 'select-class' : 'unSelect-class'"
                            v-for="(item, index) in workClassList" :key="index">
                            <div>{{ item.name }}</div>
                            <img v-if="itemForm.workOrderType === item.value" class="img1"
                                src="../../../assets/images/yunwei/select-backgroun.svg" alt="">
                            <div class="img2">
                                <img v-if="itemForm.workOrderType === item.value"
                                    src="../../../assets/images/yunwei/selected.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <div class="line-class">
                    <el-form-item label="计划开始时间：" prop="planStartTime">
                        <el-date-picker v-model="itemForm.planStartTime" type="date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划结束时间：" prop="planEndTime">
                        <el-date-picker v-model="itemForm.planEndTime" type="date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="工单级别：" prop="orderLevel">
                        <el-select v-model="itemForm.orderLevel" placeholder="请选择工单级别">
                            <el-option label="级别1" :value="0"></el-option>
                            <el-option label="级别2" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="工单内容：" prop="workOrderContent">
                    <el-input v-model="itemForm.workOrderContent">
                    </el-input>
                </el-form-item>
                <el-form-item label="修复建议：" prop="proposal">
                    <el-input v-model="itemForm.proposal">
                    </el-input>
                </el-form-item>
                <div class="line-class">
                    <el-form-item label="电站名称" prop="powerStationCode">
                      <el-input v-model="powerStationName" disabled></el-input>
                        <!-- <el-select v-model="itemForm.powerStationCode" placeholder="请选择电站">
                            <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
                                :value="item.powerStationCode">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="工单流程名称" prop="workOrderNo">
                       <el-input v-model="workOrderName" disabled></el-input>
                        <!-- <el-select v-model="itemForm.workOrderNo" placeholder="请选择工单流">
                            <el-option v-for="item in flowWorkOrderApplyList" :key="item.id" :label="item.workOrderName"
                                :value="item.workOrderNo">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                </div>
            </el-form>
            <!-- <div class="item-title">
                <div class="title-left"></div>
                <div>任务信息</div>
            </div>
            <div class="detail-table-class">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.index }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="厂站名称" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.powerStationName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务详情" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.jobInfo }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="发生时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.occurTime }}</span>
                        </template>
                    </el-table-column>
                </el-table>

            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        detailId: {
            typeof: String,
            default: ''
        }
    },
    mounted() {
        this.getDetail()
        // this.getUserInfo()
    },
    data() {
        return {
            itemForm: {},
            workClassList: [
                { name: '消缺', value: 0 },
                { name: '清洗', value: 1 },
                { name: '检修', value: 2 },
                { name: '抢修', value: 3 },
                { name: '其他', value: 4 }
            ],
            currentPage: 1,
            pageSize: 10,
            totle: 0,
            tableData: [],
            powerStationList: [],//电站列表
            alartList: [], //告警列表
            flowWorkOrderApplyList: [],//工单流程列表
            workOrderDetailInfo: {},
            powerStationName: localStorage.getItem("powerStationName"),
            workOrderName:""
        }
    },
    methods: {
        getDetail() {
            console.log("this.detailId", this.detailId);
            this.$http.post('WorkOrder/workOrderInfo', { workOrderApplyCode: this.detailId }).then(res => {
                if (res.data.code === 200) {
                  console.log(res.data.data,136);
                    this.workOrderDetailInfo = res.data.data
                    this.workOrderName = this.workOrderDetailInfo.workOrderName
                    // this.workOrderDetailInfo.jsonData = JSON.parse(this.workOrderDetailInfo.jsonData)
                    this.itemForm = {
                        workOrderType: this.workOrderDetailInfo.workOrderType,
                        orderLevel: this.workOrderDetailInfo.orderLevel,
                        powerStationCode: this.workOrderDetailInfo.powerStationOwnerUserCode,
                        workOrderName: this.workOrderDetailInfo.workOrderName,
                        workOrderNo: this.workOrderDetailInfo.workOrderNo,
                        planStartTime: this.workOrderDetailInfo.planStartTime,
                        planEndTime: this.workOrderDetailInfo.planEndTime,
                        workOrderContent: this.workOrderDetailInfo.workOrderContent,
                        proposal: this.workOrderDetailInfo.proposal,
                    }
                    console.log('this.workOrderDetailInfo', this.itemForm)

                    this.tableData = this.workOrderDetailInfo.jsonData.jobInfoList
                }
            })
        },
        handleSizeChange() { },
        handleCurrentChange() { },
        // getUserInfo() {
        //     // 告警列表
        //     this.$http.post('alarmManage/list', { currPage: 1, pageSize: 10000 }).then(res => {
        //         if (res.data.code === 200) {
        //             this.alartList = res.data.data.data
        //         }
        //     })
        //     // 电站列表
        //     this.$http.post("powerStation/list", { currPage: 1, pageSize: 10000 }).then(res => {
        //         if (res.data.code == 200) {
        //             this.powerStationList = res.data.data.data;
        //         } else {
        //             this.$message.error(res.data.message)
        //         }
        //     })
        //     // 工单流程列表
        //     this.$http.post("WorkOrder/workList", { currPage: 1, pageSize: 10000 }).then(res => {
        //         if (res.data.code == 200) {
        //             this.flowWorkOrderApplyList = res.data.data.data;
        //         } else {
        //             this.$message.error(res.data.message)
        //         }
        //     })
        // }
    }
}
</script>
<style lang="scss" scoped>
.body {
    background: #fff;
    padding: 6px;
    max-height: 550px;
    overflow-y: auto;

    .content-class {
        padding: 8px;

        .head-class {
            display: flex;
            align-items: center;
            font-family: "Arial", sans-serif;
            color: #97b3cb;
            font-size: 16px;
        }

        .item-title {
            display: flex;
            align-items: center;
            font-family: "Arial", sans-serif;
            color: #333333;
            font-size: 14px;
            margin: 15px 5px;

            .title-left {
                height: 20px;
                width: 5px;
                border-radius: 5px;
                border: 1px solid #02a7f0;
                background-color: #02a7f0;
                margin-right: 5px;
            }
        }

        .form-class {
            .work-class {
                display: flex;

                .select-class {
                    width: 105px;
                    height: 33px;
                    border-radius: 5px;
                    border: 1px solid #02a7f0;
                    background-color: #fff;
                    font-family: "Arial", sans-serif;
                    color: #02a7f0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-right: 5px;

                    .img1 {
                        width: 21px;
                        height: 15px;
                        position: absolute;
                        bottom: -1px;
                        right: -1px;
                        z-index: 1;
                    }

                    .img2 {
                        width: 19.5px;
                        height: 14px;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        background: #02a7f0;

                        img {
                            width: 16px;
                            height: 10px;
                            position: absolute;
                            bottom: 0px;
                            right: 0px;
                        }
                    }
                }

                .unSelect-class {
                    width: 105px;
                    height: 33px;
                    border-radius: 5px;
                    border: 1px solid rgba(170, 170, 170, 0.57);
                    background-color: rgba(255, 255, 255, 0);
                    font-family: "Arial", sans-serif;
                    color: #aaaaaa;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                }
            }

            .line-class {
                display: flex;

                // justify-content: space-between;
                el-form-item {
                    margin-right: 10px;
                }
            }

            .upload-col {
                img {
                    width: 60px;
                    height: 60px;
                }
            }

            .upload-col {
                img {
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }

    /deep/ .el-form-item__label {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        // line-height: 30px;
        font-size: 13px;
        text-align: right;
    }

    /deep/ .el-input__inner {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 13px;
        border: 1px solid rgba(170, 170, 170, 0.57);
    }

    /deep/ .el-form-item--small.el-form-item {
        margin-right: 20px;
    }

    /deep/ .el-textarea__inner {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #fff;
        font-size: 13px;
        border: 1px solid rgba(170, 170, 170, 0.57);
    }

    /deep/ .el-upload--picture-card {
        height: 120px;
        width: 120px;
    }

    /deep/ .el-upload-list--picture-card .el-upload-list__item {
        height: 120px;
        width: 120px;
    }
}

.detail-table-class {
    /deep/ .el-table {
        margin-top: 0px;
    }

    /deep/ .el-table--border,
    .el-table--group {
        border: 1px solid rgba(151, 179, 203, 0.55);
    }

    /deep/ .el-table--border th {
        background-color: rgba(151, 179, 203, 0.55);

        border: 1px solid #737c85;
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #ffffff;
    }

    /deep/ .el-table th {
        background-color: rgba(151, 179, 203, 0.55);
        border: 1px solid rgba(151, 179, 203, 0.55);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
    }

    /deep/ .el-table td {
        background-color: #fff;
        border: 1px solid rgba(151, 179, 203, 0.55);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
    }

    /deep/ .el-table__body-wrapper {
        background: #fff;
    }

    /deep/ .el-pagination button:disabled {
        background: #fff;
    }

    /deep/ .el-pager li.active {
        color: #f2f2f2;
        background-color: #00b2e6;
        cursor: default;
    }

    /deep/ .el-pagination {
        display: flex;
        justify-content: flex-end;
    }

    /deep/ .el-pagination__total {
        color: #fff;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        font-size: 12px;
    }

    /deep/ .el-pagination__jump {
        color: #fff;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        font-size: 15px;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: #fff !important;
    }
}


//   ::v-deep
//   .el-table--enable-row-hover
//   .el-table__body
//   tr:hover
//   > td {
// }</style>
