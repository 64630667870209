import { render, staticRenderFns } from "./workOrderDetail.vue?vue&type=template&id=6a4e7e39&scoped=true"
import script from "./workOrderDetail.vue?vue&type=script&lang=js"
export * from "./workOrderDetail.vue?vue&type=script&lang=js"
import style0 from "./workOrderDetail.vue?vue&type=style&index=0&id=6a4e7e39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4e7e39",
  null
  
)

component.options.__file = "workOrderDetail.vue"
export default component.exports