<template>
    <div class="body">
        <div class="content-class">
            <div class="item-title">
                <div class="title-left"></div>
                <div>流转意见</div>
            </div>
            <div class="flex-class">
                <div class="flex-item-class" v-for="(item,index) in flowList" :key="index">
                    <div :style="{width: index===flowList.length-1?'70px':''}">
                        <div class="point-class">{{index+1}}</div>
                    </div>
                    <div v-if="index != flowList.length-1" class="line-class">
                        <i class="el-icon-caret-right"></i>
                    </div>
                    <div class="text-class">
                        <div>{{item.title}}</div>
                        <div>{{item.name}}</div>
                        <div>{{item.date}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            flowList: [{title: '清洗工单',name: '胡浩然',date: '2024-05-21'},
                {title: '工单处理',name: '胡浩然',date: '2024-05-21'},
                {title: '工单处理',name: '胡浩然',date: '2024-05-21'},
                {title: '工单确认',name: '胡浩然',date: '2024-05-21'},
            ]
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
    .body{
        background: #fff;
        padding: 6px;
        max-height: 550px;
        width: 100%;
        overflow-y: auto;
            .item-title{
                display: flex;
                align-items: center;
                font-family: "Arial", sans-serif;
                color: #333333;
                font-size: 14px;
                margin: 15px 5px;
                .title-left{
                    height: 20px;
                    width: 5px;
                    border-radius: 5px;
                    border: 1px solid #02a7f0;
                    background-color: #02a7f0;
                    margin-right: 5px;
                }
            }
            .flex-class{
                display: flex;
                align-items: center;
                padding: 0 20px;
                .flex-item-class{
                    display: flex;
                    height: 210px;
                    align-items: center;
                    position: relative;
                    .point-class{
                        width: 55px;
                        height: 55px;
                        border: 4px solid #02a7f0;
                        border-radius: 50%;
                        background-color: #ffffff;
                        box-sizing: border-box;
                        font-family: "Arial Bold", "Arial", sans-serif;
                        font-weight: 700;
                        color: #02a7f0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                    }
                    .text-class{
                        text-align: center;
                        position: absolute;
                        top: 140px;
                        left: -10px;

                    }
                    .line-class{
                        width: 100px;
                        height: 2px;
                        background: #02a7f0;
                        position: relative;
                            /deep/ .el-icon-caret-right:before {
                                content: "\e791";
                                color: #02a7f0;
                                position: absolute;
                                top: -7px;
                                right: -3px;
                            }
                    }
                }
            }
    }
</style>
