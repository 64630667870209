import { render, staticRenderFns } from "./dealFlow.vue?vue&type=template&id=49512652&scoped=true"
import script from "./dealFlow.vue?vue&type=script&lang=js"
export * from "./dealFlow.vue?vue&type=script&lang=js"
import style0 from "./dealFlow.vue?vue&type=style&index=0&id=49512652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49512652",
  null
  
)

component.options.__file = "dealFlow.vue"
export default component.exports